import React from "react"
import LayOut from "../components/layout"
import EventsHeader from "../components/events/herobanner/index"
import EventsContent from "../components/events/eventsPageContent"
import SEO from "../components/events/seo"
// import BlogContent from "../components/blog/blogContent"
// import BlogsSEO from '../components/blog/seo';

const Blog = () => {
  return (
    <LayOut>
      <SEO />
      <div>
        <EventsHeader />
        <EventsContent />
      </div>
    </LayOut>
  )
}

export default Blog
