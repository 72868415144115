import "./eventsPageContent.sass"

import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

// import FeaturedBlogCard from './featuredBlogCard'
import { BsArrowDown } from "react-icons/bs"
import EventCard from "./eventCard"
import OnDemandCard from "./onDemandCard"
// import "./blogContent.sass"
import { Select } from "antd"
import { v4 as uuid } from "uuid"

const EventContent = () => {
  const [data, setData] = useState(null)
  const [shownNumbers, setShownNumbers] = useState(6)
  const loadMoreNumber = 4
  const [liveData, setLiveData] = useState([])
  const [onDemand, setOnDemand] = useState([])

  useEffect(() => {
    setData(allSanityEvents.edges.map(blog => blog.node))
    let topDistance = document.documentElement.scrollTop
    window.scrollTo(0, topDistance)
  }, [])

  // Filter live data
  useEffect(() => {
    if (data === null) return

    const filter = data.filter(event => event.eventStatus === "Live")
    setLiveData(filter)
  }, [data])

  // Filter on demand data
  useEffect(() => {
    if (data === null) return

    const filter = data.filter(event => event.eventStatus === "On Demand")
    setOnDemand(filter)
  }, [data])

  const { Option } = Select

  function handleChange(value) {
    if (value === "alphabetical") {
      setData(
        allSanityEvents.edges
          .map(blog => blog.node)
          .sort((a, b) => (a.title > b.title ? 1 : -1))
      )
    }
    if (value === "date") {
      setData(allSanityEvents.edges.map(blog => blog.node))
    }
  }

  const { allSanityEvents } = useStaticQuery(graphql`
    query allEvents {
      allSanityEvents(sort: { fields: _updatedAt, order: DESC }) {
        edges {
          node {
            id
            description
            eventDates {
              _key
              _type
              eventTitle
              eventDate(formatString: "MMMM DD, YYYY HH:MM")
              applicationLink
              eventDateString
            }
            image {
              asset {
                url
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
            title
            onDemandLink
            eventStatus
            eventType
            slug {
              current
            }
            order
            _updatedAt
          }
        }
      }
    }
  `)

  if (!data) return <div></div>

  return (
    <div id="events-wrapper">
      {/* <FeaturedBlogCard key={uuid()} {...data.edges[0].node} /> */}
      <div id="sortWrapper">
        <div className="sort-dropdown">
          <Select
            defaultValue="date"
            style={{ width: 150 }}
            onChange={handleChange}
            dropdownStyle={{ fontFamily: "Red Hat Display" }}
          >
            <Option value="alphabetical">Alphabetical</Option>
            <Option value="date">Date</Option>
          </Select>
        </div>
      </div>
      <div id="events-list">
        <div className="container">
          {liveData.slice(0, 40).map(blog => (
            <EventCard key={uuid()} {...blog} />
          ))}
        </div>

        {allSanityEvents.totalCount > shownNumbers ? (
          <button
            className="view-more"
            onClick={async () => {
              let topDistance = await document.documentElement.scrollTop
              await setShownNumbers(pre => pre + loadMoreNumber)
              window.scrollTo(0, topDistance)
            }}
          >
            View More
            <BsArrowDown />
          </button>
        ) : null}
      </div>

      <div id="onDemandList">
        <h2 className="pastEvents">Past Events</h2>
        <div className="container">
          {onDemand.slice(0, 40).map(blog => (
            <OnDemandCard key={uuid()} {...blog} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default EventContent
