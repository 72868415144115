import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import MyLink from "../../components/myLink"
import "./eventCard.sass"
import { Select } from "antd"

const { Option } = Select

const EventCard = ({
  image,
  eventDates,
  title,
  description,
  onDemandLink,
  eventStatus,
  eventType,
  sanityInternal,
  slug,
}) => {
  const [link, setLink] = useState("Select An Event")
  const disabledNext = link === "Select An Event" ? "disabled-link" : ""

  function handleChange(value) {
    setLink(value)
  }

  return (
    <div className="event-card">
      <GatsbyImage
        image={image?.asset?.gatsbyImageData}
        alt={image?.asset?.originalFilename}
        imgStyle={{
          objectFit: "contain",
        }}
      />
      <div className="image-banner">
        <p>LIVE</p>
      </div>
      <div className="content">
        <p>{eventType}</p>
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="dates-wrapper">
          <Select
            onChange={handleChange}
            className="select-drop"
            defaultValue="Select"
            value={link}
            dropdownStyle={{ fontFamily: "Red Hat Display" }}
          >
            {eventDates.map(eventDate => (
              <Option key={eventDate?._key} value={eventDate?.applicationLink}>
                {eventDate?.eventDateString} {eventDate?.eventTitle}
              </Option>
            ))}
          </Select>
          <MyLink
            linktext="Register"
            internallink={false}
            newWindow={true}
            url={link}
            className={disabledNext}
          />
        </div>
      </div>
      {sanityInternal ? (
        <MyLink
          linktext="Read More"
          internallink={true}
          newwindow={false}
          url={`/events/${slug.current}`}
          className="theme-blue-btn"
        />
      ) : null}
    </div>
  )
}

export default EventCard
