import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import MyLink from "../../components/myLink"
import { Link } from "gatsby"

import "./onDemand.sass"
import { Select } from "antd"

const { Option } = Select

const EventCard = ({
  image,
  eventDates,
  title,
  description,
  onDemandLink,
  eventStatus,
  eventType,
  sanityInternal,
  slug,
}) => {
  const [link, setLink] = useState("Select")

  function handleChange(value) {
    setLink(value)
  }

  return (
    <div className="onDemand-card">
      <Link to={onDemandLink} className="no-style" target="blank">
        <GatsbyImage
          image={image.asset.gatsbyImageData}
          alt={image.asset.originalFilename}
          imgStyle={{
            objectFit: "contain",
          }}
        />
        <div className="image-banner">
          <p>On-Demand</p>
        </div>
        <div className="content">
          <p>{eventType}</p>
          <h2>{title}</h2>
        </div>
      </Link>
    </div>
  )
}

export default EventCard
